$small-phone-width: 320px;
$phone-width: 480px;
$tablet-width: 768px;
$desktop-width: 992px;
$large-desktop-width: 1200px;

@mixin small-phone {
    @media only screen and (min-width: #{$small-phone-width}) {
        @content;
    }
}

@mixin phone {
    @media only screen and (min-width: #{$phone-width}) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (min-width: #{$tablet-width}) {
        @content;
    }
}

@mixin desktop {
    @media only screen and (min-width: #{$desktop-width}) {
        @content;
    }
}

@mixin large-desktop {
    @media only screen and (min-width: #{$large-desktop-width}) {
        @content;
    }
}

@mixin print {
    @media print {
        @content;
    }
}
