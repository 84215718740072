/* Do not remove this comments bellow. It's the markers used by wiredep to inject
   sass dependencies when defined in the bower.json of your dependencies */
// bower:scss
// endbower

$white: #FFFFFF;
$primary: black;
$secondary: gray;
$light-bg: #F4F4F4;
$dark-bg: gray;
$header-bg: $primary;
$sidebar-header-bg: #252728;
$toggle-active: white;
$cancel: darken($light-bg, 30%);
$error: #da4453;

$font-size: 13px;
$font-family: 'Roboto';
$base-color: black;

$header-height: 60px;
$sidebar-width: 300px;
$sidebar-width-small: 50px;

$modal-z-index: 1000;
$header-z-index: 100;

/* Do not remove this comments bellow. It's the markers used by gulp-inject to inject
   all your sass files automatically */
// injector
@import "animations.scss";
@import "mixins.scss";
@import "print.scss";
@import "list/list.scss";
@import "header/header.scss";
@import "main/main.scss";
@import "modal/modal.scss";
@import "sidebar/sidebar.scss";
@import "detail/detail.scss";
// endinjector

html, body {
    -webkit-font-smoothing: antialiased;
    font-family: $font-family;
    font-size: $font-size;
    color: $base-color;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: $light-bg;
    box-sizing: border-box;

    > section.main {
        height: 100%;
        width: 100%;
    }
}

*, *:before, *:after {
    box-sizing: inherit;
}

.fill {
    flex: 1;
}

._720kb-tooltip {
    z-index: $modal-z-index + 1;
    visibility: hidden !important;

    &._720kb-tooltip-open {
        visibility: visible !important;
    }
}

input {
    &:focus, &:active {
        outline: none;
    }
}

button {
    cursor: pointer;

    &:focus, &:active {
        outline: none;
    }
}

a {
    color: $primary;
    text-decoration: none;
    cursor: pointer;

    &:visited, &:focus, &:active {
        color: $primary;
    }
}

.card {
    background: $white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .15);
    padding: .5em;

    > .card-date {
        font-size: 0.8em;
        font-weight: 500;
        color: lighten($dark-bg, 40%);
    }

    > .card-title-category {
        > .card-title {
            font-size: 1.2em;
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 0.2em;
            margin-top: 1em;
            white-space: nowrap;
            display: block;

            color: $primary;
            text-decoration: none;

            &:visited, &:focus, &:active {
                color: $primary;
            }
        }

        > .card-category {
            display: block;
            margin-bottom: 1em;
            font-size: 1.2em;
            color: $dark-bg;
            font-weight: 600;
        }
    }

    > .card-location, > .card-type, > .card-separator {
        color: lighten($dark-bg, 20%);
        font-weight: 500;
        font-size: 1.0em;
    }
}

div.no-data-message {
    height: 100%;
    width: 100%;
    text-align: center;

    > h2 {
        font-size: 3.5em;
        font-weight: normal;
        color: lighten($dark-bg, 50%);
        margin: 10% 0 0.1em;
    }

    > h3 {
        font-size: 2.5em;
        font-weight: normal;
        color: lighten($dark-bg, 50%);
        margin: 0 0 0.5em;
    }

    > p {
        font-size: 1.3em;
    }
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.mask {
    position: fixed;
    z-index: $header-z-index;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 0;
    height: 0;
    background-color: #000;
    opacity: 0;
    transition: opacity 0.3s, width 0s 0.3s, height 0s 0.3s;

    &.active {
        width: 100%;
        height: 100%;
        opacity: 0.2;
        transition: opacity 0.3s;
    }
}

.centered {
    position: fixed;
    top: 50%;
    left: 50%;
}

