header.app {
    height: $header-height;
    min-height: $header-height;
    padding: 0 1.5em;
    text-align: center;

    background-color: $header-bg;
    color: $white;
    z-index: $header-z-index;

    @include desktop() {
        text-align: left;
    }

    > label {
        display: inline-block;
        font-size: 2em;
        font-weight: 700;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: $header-height;
        width: 75%;
    }

    > button {
        display: none;
        background: transparent;
        border: none;
        font-size: 1.8em;
        line-height: $header-height;
        margin-right: 0.5em;
        padding: 0;
        position: absolute;
        left: 1em;
        top: 0;
    }

    > button.toggle-filters {
        display: inline-block;
        z-index: $header-z-index + 1;

        > .badge {
            position: absolute;
            top: 15px;
            left: -7px;
            font-size: 12px;
            padding: 1px 5px;
            background-color: $error;
            border-radius: 50%;
            color: $white;
            line-height: 1;
        }

        > .close {
            display: none;
        }

        @include desktop() {
            display: none;
        }
    }

    // Parent selector
    .show-nav & {
        > button.toggle-filters {
            > .close {
                display: block;
            }

            > .open, > .badge {
                display: none;
            }
        }
    }

    // Parent selector
    .overview-open & {
        > button.toggle-filters {
            display: none;
        }

        > button.go-back {
            display: inline-block;

            @include desktop() {
                display: none;
            }
        }
    }
}