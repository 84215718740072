@media print {
    html {
        overflow: visible !important;
        height: initial;

        > body {
            overflow: visible !important;
            height: initial;

            section.overview-open {
                overflow: visible !important;

                > section.portal {
                    overflow: visible !important;

                    aside.sidebar {
                        display: none;
                    }

                    section.main {
                        overflow: visible !important;

                        > header {
                            button[name="filters-menu"],
                            button[name="over-arrow-back"] {
                                display: none;
                            }
                        }
                    }
                }
            }

            section.fade {
                overflow: visible !important;
                height: auto;

                > section.job-detail {
                    overflow: visible !important;
                    flex-direction: column;

                    > div.description {
                        order: 2;
                        overflow: visible !important;
                        //page-break-before: always;
                        .job-actions {
                            display: none;
                        }
                    }
                }
            }

            button.bhi-arrow-back {
                display: none;
            }

            div.apply {
                width: 100%;

                .apply,
                .break,
                .btn-li-lg,
                .category-filter,
                .related-jobs,
                hr {
                    display: none !important;
                }
            }
        }
    }
}
